import axios from "axios";
import store from '@/store/index';
import {buildUrlWithQueryParams} from "@/utils/urls";

const webApiUrl = 'https://web-api.ecarsuite.com/zoho-lead';
class ZohoService {
    access_token;

    getResellerConfig() {
        if (!this.refreshToken) this.refreshToken = localStorage.getItem('zoho_refresh_token');

        const {
            oauthUrl,
            client_id,
            client_secret,
            code,
            leadsUrl,
            refresh_token
        } = store.state.reseller.resellerInfo.settings?.zoho || {
            client_id: '1000.ZVE8L30VA6GLR3WQY1E1DKNC0E4I8F',
            client_secret: '82ecdb8f4bd21c7fd80899e4ba54baff16fdfe53c0',
            oauthUrl: 'https://accounts.zoho.eu/oauth/v2/token',
            leadsUrl: 'https://creator.zoho.eu/api/v2/crmnordics/nordics-hub-leads/form/Hub',
            refresh_token: '1000.5cf4e64989d58b6e5f40985bacfbc7f7.f4fcb80e02602528f49bf7fb489a0312',
        };

        return {
            refresh_token,
            leadsUrl,
            oauthUrl,
            client_id,
            client_secret,
            code
        }
    }
    async refreshAccessToken() {
        const {refresh_token, oauthUrl, client_id, client_secret} = this.getResellerConfig();
        if (!refresh_token) {
            console.error("No refresh token");
            return Promise.reject();
        }

        try {
            const {data: {access_token}} = await axios.post(webApiUrl, {}, {
                headers: {
                    'x-proxy-to-url': buildUrlWithQueryParams(oauthUrl, {
                        client_id,
                        client_secret,
                        refresh_token,
                        grant_type: 'refresh_token'
                    }),
                    "Content-Type": 'application/json'
                }
            });
            this.access_token = access_token;
        } catch (e) {
            console.error(e)
        }
    }

    prepareZohoLeadData(leadId) {
        const YN = (value) => value ? 'Y' : 'N';
        const {order, product, reseller} = store.state;

        const {
            resellerInfo: {
                market: {
                    country: {
                        code: countryCode,
                    }
                },
            }
        } = reseller;

        const {
            leadType,
            leadSource,
            utmSource,
            utmMedium,
            utmCampaign,
            utmTerm,
            utmContent,
            brandCode
        } = reseller.settings?.zoho
        || {
            "leadType": "TP10",
            "leadSource": "OL14",
            // "utmSource": "google",
            // "utmMedium": "search",
            // "utmCampaign": "new_release",
            // "utmTerm": "renault",
            // "utmContent": "image-10",
            "brandCode": "RNL",
        }


        const {
            firstName,
            lastName,
            companyName,
            email,
            phone,
            comments,
            zipCode,
            city,
            address,
        } = order;

        const {
            vehicle: {
                model_code: modelCode,
                engine,
                roadsideAssist,
                name: namePowertrain,
                configuration: {
                    gear: gearType,
                    operation_mode: operationMode
                },
            },
            vehicleColor: {
                modelColor: {
                    name: colour
                }
            },
        } = product;


        const {
            months: noOfMonths,
            service_cost: serviceCost,
            distance: annualDistanceMiles
        } = store.getters["product/getCurrentCostsObject"];

        const {
            over_miles: overMilesFee,
            under_miles: underMilesPayback,
            rate: interestRate,
        } = store.getters["product/getCurrentPricesObject"];


        const grade = store.getters["product/grade"];
        const accessoriesOtherInfo = store.getters["financing/getSelectedAccessoriesString"];
        const equipmentSpecs = store.getters["financing/equipmentSpecsString"];
        const dealershipCode = store.getters["order/selectedDealerCode"];
        const dealershipName = store.getters["order/selectedDealerName"];

        const retailSalesPriceRSP = store.getters['financing/vehicleRetailPrice'];
        const retailSalesPriceRSPInclAccessories = store.getters['financing/retailSalesPriceRSPInclAccessories'];
        const netPrice = store.getters['financing/vehicleNetPrice'];
        const netPriceInclAccessories = store.getters['financing/netPriceInclAccessoriesAndVehicleColor'];
        const monthlyCost = store.getters['financing/monthlyCostOfVehicle'];
        const monthlyCostInclAccessories = store.getters['financing/totalMonthlyCost'];
        const totalResidualAmount = store.getters['financing/residualValue'];
        const totalResidualAmountInclAccessories = store.getters['financing/residualValueInclAccessories'];
        const downpayment = store.getters['financing/cashPayment'];

        const requestType = {
            ['Private leasing']: 'TPD21',
            ['Down payment']: 'TPD22',
        }[product.priceForm];

        const formType = {
            ['Private leasing']: 'F27',
            ['Down payment']: 'F28',
        }[product.priceForm];

        return {
            data: {

                leadId,
                requestType,
                leadType,
                formType,
                leadSource,
                brandCode,
                receiveInformation: null, // todo: change if newsletter will be added
                privacyPolicy: YN(true),
                "calculationMethod": "Residual Value", // note: it also may be fixed price in future

                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,

                firstName,
                lastName,
                companyName,
                address,
                zipCode,
                city,
                countryCode: countryCode.toUpperCase(),
                phone,
                email,

                noOfMonths,
                message: comments,
                dealershipCode,
                dealershipName,

                modelCode,
                overMilesFee,
                interestRate,
                annualDistanceMiles,
                serviceCost,
                grade,
                namePowertrain,
                colour,
                fuelPowertrain: engine,
                gearType,
                operationMode,
                underMilesPayback,
                Road_side_assistance: roadsideAssist.name,

                accessoriesOtherInfo,
                equipmentSpecs,

                retailSalesPriceRSP,
                retailSalesPriceRSPInclAccessories,
                netPrice,
                netPriceInclAccessories,
                monthlyCost,
                monthlyCostInclAccessories,
                totalResidualAmount,
                totalResidualAmountInclAccessories,
                downpayment,
            }
        }
    }

    async sendZohoLead(leadId, data = null, attempt = 0) {
        if (!window.location.hostname.includes("renault.se")) return;
        const {leadsUrl} = this.getResellerConfig()

        if (!this.access_token) {
            await this.refreshAccessToken();
        }

        try {
            if (!data) data = this.prepareZohoLeadData(leadId)
            await axios.post(webApiUrl, data, {
                headers: {
                    'Authorization': `Zoho-oauthtoken ${this.access_token}`,
                    'x-proxy-to-url': leadsUrl,
                }
            })
        } catch (e) {
            if (e.response?.status === 401) {
                await this.refreshAccessToken();
                if (attempt < 3) return this.sendZohoLead(data, attempt + 1);
            }
            console.error(e)
        }

    }
}

export default new ZohoService;