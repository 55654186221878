<template lang="pug">
StepViewsContainerOld(:isConfirmed="isConfirmed" v-if="!isDesktop() || !useNewDesign")
StepViewsContainerNew(:isConfirmed="isConfirmed" v-else-if="useNewDesign && isDesktop()")
</template>

<script>
  /* eslint-disable */
  import { isDesktop } from '../helpers/helpers.js'
  import StepViewsContainerOld from '../components/step-views/step-views-regular/StepViewsContainer'
  import StepViewsContainerNew from '../components/step-views/step-views-new/StepViewsContainer'

  import {mapState} from "vuex";
  export default {
    name: 'Product',
    props: {
      isConfirmed: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      isDesktop
    },
    components: {
      StepViewsContainerOld,
      StepViewsContainerNew
    },
    computed: {
        ...mapState('reseller', {useNewDesign: state => state.resellerInfo.settings?.useNewDesign,}),
    }
  }
</script>
