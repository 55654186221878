<template lang="pug">
pre-loader(:show="orderSend" size="lg")
  .page.page-model.page-model-confirmed(v-if="isConfirmed" )
    .container
      .result-model-img
        v-image(:image-source="vehicleInfo.image" :alt="vehicleInfo ? vehicleInfo.name : ''")
      .result-model-name.m-t-10
        span.model-title
          | {{ customStyle.headline }}
      .result-model-desc.m-t-10 {{ customStyle.intro }}
      .title-separ
        span.title-separ-text {{ customStyle.title }}
      .row.row-steps.m-t-50.justify-content-md-center(
        v-if="!!customStyle.items && !!customStyle.items.data.length"
      )
        .col-12.col-lg-2(v-for="(item, key) in customStyle.items.data" :key="key")
          .next-step
            .next-step-icon
              img(:src="item.image" :alt="item.title")
            .next-step-num
              span.num {{ key + 1 }}
            .next-step-text
              .next-step-title {{ item.title }}
              .next-step-desc {{ item.description }}
</template>

<script>
  import { mapState, } from 'vuex'
  import ProductStepMixin from '@/mixins/ProductStepMixin'

  import VImage from '@/components/helps/Image'

  export default {
    name: 'StepConfirmed',
    props: {
      isConfirmed: {
        type: Boolean,
        default: false
      }
    },
    components: {
      VImage
    },
    data: () => ({
      orderSend: true,
      vehicleInfo: JSON.parse(localStorage.getItem('vehicleInfo')),
    }),
    mixins: [ProductStepMixin],
    created() {
      this.orderSend = false;
    },
    computed: {
      ...mapState('reseller', {
        customStyle: state => state.customStyle.confirmPage || [],
      }),
    },
  }
</script>

<style lang="sass">
  .model
    color: #676767
    font-size: 38px
</style>
