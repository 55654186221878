import store from '@/store/index';

export default function (responseFromSalesForce) {
  // eslint-disable-next-line no-undef
  return axios.post('/reseller/save-sf-data', responseFromSalesForce, {
    headers: {
      'Authorization': store.state.reseller.partnerKey,
    }
  })
}
