<template lang="pug">
.page.page-model
  SpecificationPopup(v-show="showSpecification && nfActiveStep.index === 1" :specs="specsList" @closeSpecifications="closeSpecifications")
  .container
    .row
      .col-lg-8
        VehicleModel(:title="vehicleTitle" :description="fullVehicleSubName")
        v-galery(
          :main-image-url="imageUrl"
          :images="vehicle.images ? vehicle.images.data : []"
          :alt="alt"
          :showThumbs="true"
        )
        VehicleColor

      .col-lg-4
        VehicleDescription
        div.example-equipment-box
          div(v-html="vehicleDescription" )
          br
          button.collapse-button.btn-hover.invert(@click="showSpec") {{ $t('collapseBox.seeMore') }}

  div.sticky-options
    Costs(@clickBack="goToPrev" v-show="nfActiveStep.index === 2")
    StepsContainer(@clickBack="goToPrev" v-show="optionalAccessories.length && nfActiveStep.index === 3" :title="$t('stepGeneral.accessories')" )
      SelectAccessories(:accessories="optionalAccessories")
    StepsContainer(@clickBack="goToPrev" v-show="wheelsAccessories.length && nfActiveStep.index === 4" :title="$t('stepGeneral.winterTyres')" )
      SelectAccessories(:accessories="wheelsAccessories")
    StepsContainer(@clickBack="goToPrev" v-show="insurance.length && nfActiveStep.index === 5" :title="$t('stepGeneral.insurance')" )
      SelectAccessories(:accessories="insurance")
    StepsContainer(v-show="tradeIn.active && nfActiveStep.index === 6" :title="$t(\"steps.trades.title\")")
      Trades
</template>

<script>
  import { mapMutations, mapState, mapGetters, } from 'vuex'

  import moment from 'moment'

  import CollapseBox from '@/components/helps/CollapseBox'

  import Datepicker from 'vuejs-datepicker'

  import { sv } from 'vuejs-datepicker/dist/locale'

  // eslint-disable-next-line no-unused-vars

  import {
    getDaysOrWeeks,
    isRenault,
    isDacia,
    isRental,
    isDanmarkR,
    isDanmarkD,
    splitUp
  } from '../../../helpers/helpers.js'

  import ProductStepMixin from '@/mixins/ProductStepMixin'

  export default {
    name: 'StepGeneral',
    components: {
      CollapseBox,
      Datepicker,
      Popup: () => import('@/components/helps/Popup'),
      Check: () => import('@/components/svg/check.vue'),
      VehicleDescription: () => import('@/components/steps-faceless/VehicleDescription'),
      VehicleModel: () => import('@/components/steps-faceless/VehicleModel'),
      EffectiveRanta: () => import('@/components/steps-faceless/step-general/EffectiveRanta'),
      VehicleColor: () => import('@/components/steps-faceless/step-general/VehicleColor'),
      MainVehicleInfo: () => import('@/components/steps-faceless/MainVehicleInfo'),
      DetailedVehicleInfo: () => import('@/components/steps-faceless/DetailedVehicleInfo'),
      SelectAccessories: () => import('@/components/steps-faceless/SelectAccessories'),
      StepsContainer: () => import('@/components/steps-faceless/StepsContainer'),
      CostDescription: () => import('@/components/steps-faceless/CostDescription'),
      SpecificationPopup: () => import('@/components/steps-faceless/SpecificationPopup'),
      Costs: () => import('@/components/steps-faceless/step-general/Costs'),
      Trades: () => import('@/components/steps-faceless/Trades/Trades'),
      VGalery: () => import('@/components/helps/Galery'),

    },
    mixins: [ ProductStepMixin, ],
    data: () => ({
      sv: sv,
      isPopup: false,
      showSpecification: false
    }),
    computed: {
      ...mapState('steps', [ 'nfSteps', 'nfActiveStep' ]),
      ...mapState('global', ['isDisabled']),
      ...mapState('reseller', {
        customStyle: state => state.customStyle.firstStep || {},
        resellerInfo: state => state.resellerInfo,
        partnerKey: state => state.partnerKey,
        hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
        times: state => state.resellerInfo.settings?.calendar?.rental.pickup_times || [],
        minDaysFromOrder: state => state.resellerInfo.settings?.calendar?.rental.pickup_min_days_from_order || 0,
        firstStepButton: state => state.customStyle.firstStep?.submit_btn_text || '',
        accessoryStyle: state => state.customStyle.accessoryPage || {},
        accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || '',
        tradeIn: (state) => state.resellerInfo.settings?.tradeIn || {},
        deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
        currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
        market: state => state.resellerInfo?.market || {},
      }),
      ...mapState('filter', ['finance_form_name']),
      ...mapState('order', ['status', 'accessories']),
      ...mapState('product', [
        'priceForm',
      ]),
      ...mapGetters('product', [
        'deliveryDaysCount',
        'deliveryDaysCountFrom',
        'deliveryDaysCountTo',
      ]),
      imageUrl() {
        return this.vehicleColor.image || this.vehicleColor.modelColor?.image.image || ''
      },
      alt() {
        return `${this.vehicle.brand} ${this.vehicle.model}`
      },
      downloadDocName() {
        // eslint-disable-next-line
        let regEx = /([a-zA-Z0-9\s_\\.\-\(\):])+\.[0-9a-z]+$/;

        return regEx.exec(this.vehicle.pdf)[0];
      },
      downloadDocType() {
        let regEx = /\.[0-9a-z]+$/;

        return regEx.exec(this.vehicle.pdf)[0];
      },
      pickUpTime: {
        get() {
          return this.$store.state.order.pickUpTime
        },
        set(value) {
          this.$store.commit('order/setPickUpTime', value)
        }
      },
      pickUpDate: {
        get() {
          return this.$store.state.order.pickUpDate
        },
        set(value) {
          this.$store.commit('order/setPickUpDate', moment(value).format('Y-MM-DD'))
        }
      },
      disabledDates() {
        const daysFrom = this.deliveryDaysCount || this.minDaysFromOrder;

        return {
          to: moment().add(daysFrom, 'days').toDate()
        }
      },
      isDownPayment() {
        return this.priceForm === 'Down payment'
      },
      computedSpecs() {
        return this.vehicle.specs_list ? this.vehicle.specs_list.slice(0, 4) : []
      },
      specsList() {
        if (this.vehicle.specs_list && this.vehicle.specs_list.length) {
          const specsCopy = [...this.vehicle.specs_list]
          return splitUp(specsCopy, 3)
        } else if (this.vehicle.specs && this.vehicle.specs.length){
          const specsCopy = [...this.vehicle.specs]
          return  splitUp(specsCopy, 3)
        }
        return []
      },
      vehicleDescription() {
        return this.vehicle.description ? this.vehicle.description : ''
      }
    },
    methods: {
      isRental,
      ...mapMutations('order', [
        'setPostalCodeId',
        'setHomeDelivery',
      ]),
      ...mapMutations('product', [
        'setVehicleColor',
        'setDistance',
        'setMonth',
        'updateVehicle',
      ]),
      setDataLayerForDownloadBrochure() {
        if (
          Object.keys(this.vehicle).length !== 0 &&
          this.vehicle &&
          isDanmarkR() && isDanmarkD() && isRenault() && isDacia()) {

          //eslint-disable-next-line
          dataLayer.push({
            brand: this.vehicle.brand,
            googleAccount: this.googleAcc,
            pageName: this.$route.name,
            languageCode: document.documentElement.lang,
            countryCode: document.querySelector('html').code,
            brochureName: this.downloadDocName,
            brochureType: this.downloadDocType,
            vehicleCategory: this.vehicle.type,
            vehicleModel: this.vehicle.model,
            vehicleId: this.vehicle.id,
            dealerName: this.resellerInfo.name,
            dealerId: this.resellerInfo.id,
            event: 'download',
            // event: 'virtualPageview',
          });
        }
      },
      getDays(days, flag) {
        const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

        return `${daysParam.number} ${daysParam.text}`;
      },
      showPopup(e) {
        if (this.isDownPayment && e.target.checked) {
          this.$set(this.$data, 'isPopup', true);
        }
      },
      closePopup() {
        this.$set(this.$data, 'isPopup', false);
      },
      closeSpecifications() {
        this.$set(this.$data, 'showSpecification', false);
      },
      showSpec() {
        this.$set(this.$data, 'showSpecification', true);
      }
    },
    watch: {
      vehicle: function (n) {
        const unconfirmedStatus = this.resellerInfo.settings && this.resellerInfo.settings.bankId
          && this.resellerInfo.settings.bankId.active ? this.resellerInfo.settings.bankId.unconfirmed_status : 1;
        const isVehicleColors = this.vehicleColorId && this.vehicle.colors && this.vehicle.colors.data.length;

        this.$store.dispatch('order/saveAllFields', {
          'vehicleId': n.id,
          'status': unconfirmedStatus,
        });

        if (isVehicleColors) {
          this.vehicleColor = this.vehicle.colors.data.find(item => item.id === this.vehicleColorId)
        }
      },
      pickUpDate: function (n) {
        if (n) {
          this.$store.dispatch('order/saveAllFields', {'pickUpDate': moment(n).format('Y-MM-DD')})

          this.pickUpDate = n
        }
      },
      pickUpTime: function (n) {
        if (n) {
          this.$store.dispatch('order/saveAllFields', {'pickUpTime': n})
        }
      },
      deliveryDaysCount: function (n) {
        if (n) {
          this.pickUpDate = moment().add(n + 1, 'days').toDate()
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '@/style/components/StepGeneral.sass'
  .sticky-options
    position: sticky
    bottom: 55px
    z-index: 99
</style>
