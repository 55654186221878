<template lang="pug">
.page.page-model(v-if="active")
  .container
    .row
      .col-md-6.order-md-1.order-2
        .m-b-0
          v-galery(
            :main-image-url="vehicleColor && vehicleColor.modelColor && vehicleColor.modelColor.image ? vehicleColor.modelColor.image.image : ''"
            :images="vehicle.images ? vehicle.images.data : []"
            :alt="alt"
          )
          template(v-if="vehicleColor")
            .d-block.d-lg-none.vehicle-color-block.m-t-10
              span
                b {{ $t("info.color") }}:
                span {{ vehicleColor.modelColor ? vehicleColor.modelColor.name : '' }}
                span.red-text &nbsp;(+&nbsp;{{ formatPrice(vehicleColor.calculated_price) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }})
              br
              span(v-if="hasDeliveryTime")
                b {{ deliveryTimeText }}:&nbsp;
                span(v-if="vehicleColor.delivery_days_from !== null && vehicleColor.delivery_days_to !== null")
                  | {{getDays(vehicleColor.delivery_days_from, false)}} - {{getDays(vehicleColor.delivery_days_to, 'short')}}
                  | {{ $t("stepGeneral.deliveryOnSelectedColor") }}
                span(v-else)
                  | {{ deliveryDaysCountFrom.number }}
                  | {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
                  | {{ deliveryDaysCountTo.number }}
                  | {{ deliveryDaysCountTo.text }}
          .d-md-none.d-lg-none(v-if="vehicleColor")
            .step-subtitle.m-t-20 {{ $t("stepGeneral.selectColor") }}
            .step-subtitle-container
              .color-check.m-t-10
                template(v-for="(color, index) in vehicleColors")
                  input.color-check-input(
                    type="radio"
                    data-vv-name="vehicleColor"
                    v-model="vehicleColorId"
                    v-validate="'required'"
                    :checked="vehicleColorId === color.id"
                    :id="`radioColor${index}`"
                    :value="color.id"
                    :title="color.modelColor.name"
                    @change="setVehicleColor(color)"
                  )
                  label.color-check-item(
                    :for="`radioColor${index}`"
                    :title="color.modelColor.name"
                  )
                    .model-color(
                      :class="{'model-color-met' : color.modelColor.metallic}"
                      :style="getColorStyle(color.modelColor)"
                    )
                      i.fas.fa-check
              small.text-danger {{ errors.first('vehicleColor:required') }}
      .col-md-6.order-md-2.order-1.vehicle-desc-block
        VehicleDescription(
          :title="vehicleTitle"
          :description="fullVehicleSubName"
        )
    .row
      .col-md-6.order-md-1.order-2
        .d-none.d-md-block
          .step-title.m-t-10 1. {{ customStyle.headline }}
          .step-text.m-t-10 {{ customStyle.intro }}
    .row
      .col-md-6.order-md-1.order-2
        .d-sm-none.d-xs-none.d-md-block.d-lg-block.d-none
          .step-subtitle.m-t-20 {{ $t("stepGeneral.selectColor") }}
          .step-subtitle-container
            .color-check.m-t-10
              template(v-for="(color, index) in vehicleColors")
                input.color-check-input(
                  type="radio"
                  data-vv-name="vehicleColor"
                  v-model="vehicleColorId"
                  v-validate="'required'"
                  :checked="vehicleColorId === color.id"
                  :id="`radioColor${index}`"
                  :value="color.id"
                  :title="color.modelColor.name"
                  @change="setVehicleColor(color)"
                )
                label.color-check-item(
                  :for="`radioColor${index}`"
                  :title="color.modelColor.name"
                )
                  .model-color(
                    :class="{'model-color-met' : color.modelColor.metallic}"
                    :style="getColorStyle(color.modelColor)"
                  )
                    i.fas.fa-check
            .d-sm-none.d-xs-none.d-md-block.d-lg-block.d-none.vehicle-color-block
              span
                b {{ $t("info.color") }}:
                span {{ vehicleColor.modelColor ? vehicleColor.modelColor.name : '' }}
                span.red-text &nbsp;(+&nbsp;{{ formatPrice(vehicleColor.calculated_price) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }})
              br
              span(v-if="hasDeliveryTime")
                b {{ deliveryTimeText }}:&nbsp;
                span(v-if="vehicleColor.delivery_days_from !== null && vehicleColor.delivery_days_to !== null")
                  | {{getDays(vehicleColor.delivery_days_from, false)}} - {{getDays(vehicleColor.delivery_days_to, 'short')}}
                  | {{ $t("stepGeneral.deliveryOnSelectedColor") }}
                span(v-else)
                  | {{ deliveryDaysCountFrom.number }}
                  | {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
                  | {{ deliveryDaysCountTo.number }}
                  | {{ deliveryDaysCountTo.text }}
            small.text-danger {{ errors.first('vehicleColor:required') }}

        .step-subtitle.m-t-20 {{ $t("stepGeneral.chooseFinancial") }}
        .step-subtitle-container
          Costs

        .step-subtitle.m-t-20 {{$t('stepGeneral.accessories')}}
        .step-subtitle-container.m-t-20
          p {{ accessoryStyle.intro }}

          label.custom-check.m-t-30.m-b-10(
            v-for="(accessorItem, index) in optionalAccessories"
            :for="`accessor${index}`"
            :class="{'is-invalid':errors.has('orderAccessories')}"
          )
            input.custom-check-input(
              type="checkbox"
              v-model="selectedAccessories"
              :value="accessorItem.id"
              :checked="!accessorItem.opt_in"
              :disabled="!accessorItem.opt_in"
              :id="`accessor${index}`"
              @change="showPopup($event)"
            )
            .check
              Check

            .custom-check-text.justify-content-between
              span.custom-check-text-block
                span {{ accessorItem.name }}
                span.custom-check-text-description {{ accessorItem.description }}
              span.red-text +&nbsp;{{ formatPrice(accessorItem.calculated_price) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

          div.m-t-40.m-b-20.info-block(v-if="accessoryStyle.popup_text && isPopup " @click="closePopup")
            EffectiveRanta
            i.close.fa.fa-times-circle(
              aria-hidden="true"
              @click="closePopup"
            )
        .step-subtitle.m-t-20 {{$t('stepGeneral.winterTyres')}}
        .step-subtitle-container.m-t-20
          p {{ accessoryStyle.intro }}

          label.custom-check.m-t-30.m-b-10(
            v-for="(accessorItem, index) in wheelsAccessories"
            :for="`tyres${index}`"
            :class="{'is-invalid':errors.has('orderAccessories')}"
          )
            input.custom-check-input(
              type="checkbox"
              v-model="selectedAccessories"
              :value="accessorItem.id"
              :checked="!accessorItem.opt_in"
              :disabled="!accessorItem.opt_in"
              :id="`tyres${index}`"
              @change="showPopup($event)"
            )
            .check
              Check

            .custom-check-text.justify-content-between
              span.custom-check-text-block
                span {{ accessorItem.name }}
                span.custom-check-text-description {{ accessorItem.description }}
              span.red-text +&nbsp;{{ formatPrice(accessorItem.calculated_price) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

          div.m-t-40.m-b-20.info-block(v-if="accessoryStyle.popup_text && isPopup " @click="closePopup")
            EffectiveRanta
            i.close.fa.fa-times-circle(
              aria-hidden="true"
              @click="closePopup"
            )
        .step-subtitle.m-t-20(v-if="insurance.length") {{$t('stepGeneral.insurance')}}
        .step-subtitle-container.m-t-20(v-if="insurance.length")
          p {{ accessoryStyle.intro }}

          label.custom-check.m-t-30.m-b-10(
            v-for="(accessorItem, index) in insurance"
            :for="`insurance${index}`"
            :class="{'is-invalid':errors.has('orderAccessories')}"
          )
            input.custom-check-input(
              type="checkbox"
              v-model="selectedAccessories"
              :value="accessorItem.id"
              :checked="!accessorItem.opt_in"
              :disabled="!accessorItem.opt_in"
              :id="`insurance${index}`"
              @change="showPopup($event)"
            )
            .check
              Check

            .custom-check-text.justify-content-between
              span.custom-check-text-block
                span {{ accessorItem.name }}
                span.custom-check-text-description {{ accessorItem.description }}
              span.red-text +&nbsp;{{ formatPrice(accessorItem.calculated_price) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

          div.m-t-40.m-b-20.info-block(v-if="accessoryStyle.popup_text && isPopup " @click="closePopup")
            EffectiveRanta
            i.close.fa.fa-times-circle(
              aria-hidden="true"
              @click="closePopup"
            )




        div(v-if="resellerInfo.settings && resellerInfo.settings.homeDelivery && vehicle.home_delivery")
          .step-subtitle.m-t-20
            strong {{ customStyle.delivery_title }}

          .row.m-t-15
            .col-md-4.col-6
              input.radio-block-input(
                type="radio"
                data-vv-name="delivery_type"
                id="delivery_type_stardard"
                value="delivery_type_stardard"
                v-model="deliveryType"
                v-validate="'required'"
                :checked="true"
              )
              label.radio-block(for="delivery_type_stardard")
                .radio-block-title {{ $t("stepGeneral.pickUpYourself") }}
                .radio-block-info {{ $t("steps.costs.standard") }}

            .col-md-4.col-6
              input.radio-block-input(
                type="radio"
                data-vv-name="delivery_type"
                id="delivery_type_home"
                value="delivery_type_home"
                v-model="deliveryType"
                v-validate="'required'"
              )
              label.radio-block(for="delivery_type_home")
                .radio-block-title {{ $t("stepGeneral.homeDelivery") }}
                .radio-block-info.red-text
                  | + {{ homeDelivery }} {{ currencySymbol }}/{{ $t('mon') }} <br>

        div(v-if="deliveryType === 'delivery_type_stardard'")
          .step-subtitle.m-t-20
            strong {{ customStyle.location_title }}

          .input-group.m-t-15.m-b-20
            select.custom-select(
              v-model="locationId"
              v-validate="'required'"
              data-vv-name='location'
              :class="{'is-invalid':errors.has('location')}"
            )
              option(selected="" disabled="" value="") {{ $t("stepGeneral.selectCollection") }}
              option(
                v-for="(location, index) in locations"
                :value='location.id'
                :key="index"
              ) {{ location.name }} {{ location.dealer ? '- ' + location.dealer : '' }}

        div(v-if="deliveryType === 'delivery_type_home'")
          .step-subtitle.m-t-20
            strong {{ $t("stepGeneral.postnumber") }}

          .input-group-nowrap.m-t-15.m-b-20
            the-mask.form-control(
              mask="#####"
              data-vv-name="postcode"
              v-validate="'required|numeric|min:5|max:5'"
              v-model="postcode"
              :class="{'is-invalid':errors.has('postcode')}"
              :error="errors.first('postcode')"
              @input="postcodeInput"
            )

            button.btn.btn-general(
              :disabled="errors.has('postcode')"
              @click.prevent="checkPostCode"
            ) {{ $t("stepGeneral.checkPostcode") }}

          div
            .m-t-15.m-b-20.invalid(
              v-if="errors.has('postcode')"
            ) {{ $t("stepGeneral.postcodeMinValid") }}

            .input-group.m-t-15.m-b-20(
              :class="{'postcode_validation': !waitingForResponse}"
            ) {{ $t("stepGeneral.checking") }}...

            .input-group.m-t-15.m-b-20(
              class="postcode_validation"
              :class="{'invalid': postcode_validation === 'invalid'}"
            ) {{ $t("stepGeneral.homeDeliveryError") }}

            .input-group.m-t-15.m-b-20(
              class="postcode_validation"
              :class="{'valid': postcode_validation === 'valid'}"
            ) {{ $t("stepGeneral.homeDeliverySuccess") }}

        div(
          v-if="isRental(this.priceForm)"
        )
          .step-subtitle.m-t-20 {{ customStyle.delivery_title }}
          .row.row-date-time.m-b-20
            .col-md-6
              .input-group.input-group_date-time.m-t-15
                .input-group-prepend(style="width: 100%")
                  span.input-group-text
                    i.far.fa-calendar-alt
                  datepicker(
                    ref="datePicker"
                    :class="{'is-invalid': errors.has('pickUpDate')}"
                    placeholder="Välj ett datum"
                    v-validate="'required'"
                    data-vv-name="pickUpDate"
                    :disabled-dates="disabledDates"
                    v-model="pickUpDate"
                    :monday-first="true"
                    input-class="form-control date-picker"
                    :language="sv"
                  )
            .col-md-6
              .input-group.input-group_date-time.m-t-15
                .input-group-prepend
                  span.input-group-text
                    i.far.fa-clock
                select#step-time.custom-select(
                  v-model="pickUpTime"
                  v-validate="'required'"
                  data-vv-name="pickUpTime"
                  :class="{'is-invalid': errors.has('pickUpTime')}"
                )
                  option(selected="" disabled="" value="") {{ $t("steps.costs.chooseTime") }}
                  option(
                    v-for="(item, index) in times"
                    :value="item.time"
                    :key="index"
                  ) {{ item.time }}

        .m-t-40.m-b-20.info-block(v-if="tradeIn.active")
          Trades

        .step-btns.m-t-20.d-none.d-lg-block
          button.step-btn.btn-hover(@click.prevent="goToNext" :disabled="isDisabled || (deliveryType === 'delivery_type_stardard' ? hasErrors : hasErrors && !postcode_validation === 'valid')")
            | {{ accessoryPageButton }}
            i.fas.fa-chevron-right


      .col-md-6.order-md-2.order-1.m-t-lg-20
        MainVehicleInfo

        a.pdf-link.m-t-10(
          target="_blank"
          v-if="vehicle.pdf"
          :href="vehicle.pdf"
          @click="setDataLayerForDownloadBrochure()"
        )
          img(src="~@/assets/img/pdf.svg" alt="pdf")
          | {{ $t("stepGeneral.downloadBrochure") }}
        .step-text.m-t-10(v-html="vehicle.description")
        .step-subtitle.m-t-20 {{ $t("stepGeneral.examplesOfEquipment") }}
        collapse-box.custom-style-box(:min-height="183" :overflow="true")
          div.text-left(v-if="useNewDesign")
            div(v-if="newDesignSpecs.isSpecsList" v-for="(spec, index) in newDesignSpecs.specs" :key="index") {{ spec }}
            div(v-if="!newDesignSpecs.isSpecsList" v-html="newDesignSpecs.specs")
          div.text-left(v-else v-html="vehicle.specs")
</template>

<script>
  import { mapMutations, mapState, mapGetters, } from 'vuex'

  import moment from 'moment'

  import CollapseBox from '@/components/helps/CollapseBox'

  import Datepicker from 'vuejs-datepicker'

  import { sv } from 'vuejs-datepicker/dist/locale'
  
  // eslint-disable-next-line no-unused-vars

  import { getDaysOrWeeks, formatPrice, isRenault, isDacia, isRental, isDanmarkR, isDanmarkD } from '../../../helpers/helpers.js'

  import ProductStepMixin from '@/mixins/ProductStepMixin'

  export default {
    name: 'StepGeneral',
    components: {
      CollapseBox,
      Datepicker,
      Popup: () => import('@/components/helps/Popup'),
      Check: () => import('@/components/svg/check.vue'),
      VehicleDescription: () => import('@/components/steps/VehicleDescription'),
      EffectiveRanta: () => import('@/components/steps/step-general/EffectiveRanta'),
      MainVehicleInfo: () => import('@/components/steps/MainVehicleInfo'),
      DetailedVehicleInfo: () => import('@/components/steps/DetailedVehicleInfo'),
      CostDescription: () => import('@/components/steps/CostDescription'),
      Costs: () => import('@/components/steps/step-general/Costs'),
      Trades: () => import('@/components/steps/Trades/Trades'),
      VGalery: () => import('@/components/helps/Galery'),
    },
    mixins: [ ProductStepMixin, ],
    data: () => ({
      sv: sv,
      postcode: '',
      postcode_validation: '',
      waitingForResponse: false,
      isPopup: false,
    }),
    created() {
      this.setHomeDelivery(this.deliveryType === 'delivery_type_home');
    },
    computed: {
      ...mapState('global', ['isDisabled']),
      ...mapState('reseller', {
        customStyle: state => state.customStyle.firstStep || {},
        resellerInfo: state => state.resellerInfo,
        partnerKey: state => state.partnerKey,
        hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
        times: state => state.resellerInfo.settings?.calendar?.rental.pickup_times || [],
        minDaysFromOrder: state => state.resellerInfo.settings?.calendar?.rental.pickup_min_days_from_order || 0,
        firstStepButton: state => state.customStyle.firstStep?.submit_btn_text || '',
        accessoryStyle: state => state.customStyle.accessoryPage || {},
        accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || '',
        tradeIn: (state) => state.resellerInfo.settings?.tradeIn || {},
        deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
        currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
        market: state => state.resellerInfo?.market || {},
      }),
      ...mapState('filter', ['finance_form_name']),
      ...mapState('order', ['status', 'accessories']),
      ...mapState('product', [
        'priceForm',
      ]),
      ...mapGetters('product', [
        'deliveryDaysCount',
        'deliveryDaysCountFrom',
        'deliveryDaysCountTo',
      ]),
      ...mapGetters('financing', ['totalMonthlyCost']),
      vehicleColorId: {
        get() {
          return this.$store.state.order.vehicleColorId;
        },
        set(value) {
          this.$store.commit('order/setVehicleColorId', value)
        }
      },
      locationId: {
        get() {
          return this.$store.state.order.locationId
        },
        set(value) {
          this.$store.commit('order/setLocationId', value)
        }
      },
      deliveryType: {
        get() {
          return this.$store.state.product.deliveryType
        },
        set(value) {
          this.$store.commit('product/setDeliveryType', value)

          this.setHomeDelivery(this.deliveryType === 'delivery_type_home')

          this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': this.totalMonthlyCost})
        }
      },
      locations() {
        return this.vehicle.locations ? this.vehicle.locations.data : []
      },
      location() {
        return this.vehicle.locations ? this.vehicle.locations.data.find(item => item.id === this.locationId) : {}
      },
      alt() {
        return `${this.vehicle.brand} ${this.vehicle.model}`
      },
      downloadDocName() {
        // eslint-disable-next-line
        let regEx = /([a-zA-Z0-9\s_\\.\-\(\):])+\.[0-9a-z]+$/;

        return regEx.exec(this.vehicle.pdf)[0];
      },
      downloadDocType() {
        let regEx = /\.[0-9a-z]+$/;

        return regEx.exec(this.vehicle.pdf)[0];
      },
      homeDelivery() {
        return this.isHasPriceData('home_delivery');
      },
      pickUpTime: {
        get() {
          return this.$store.state.order.pickUpTime
        },
        set(value) {
          this.$store.commit('order/setPickUpTime', value)
        }
      },
      pickUpDate: {
        get() {
          return this.$store.state.order.pickUpDate
        },
        set(value) {
          this.$store.commit('order/setPickUpDate', moment(value).format('Y-MM-DD'))
        }
      },
      disabledDates() {
        const daysFrom = this.deliveryDaysCount && this.deliveryDaysCount !== null ? this.deliveryDaysCount : this.minDaysFromOrder;

        return {
          to: moment().add(daysFrom, 'days').toDate()
        }
      },
      selectedAccessories: {
        get() {
          return this.$store.state.product.selectedAccessories
        },
        set(value) {
          this.$store.commit('product/setSelectedAccessories', value)
        }
      },
      isDownPayment() {
        return this.priceForm === 'Down payment'
      },
      useNewDesign() {
        return !!this.resellerInfo.settings?.useNewDesign
      },
      newDesignSpecs() {
        const isSpecsList = !!(this.vehicle.specs_list && this.vehicle.specs_list.length)
        const specs = this.vehicle.specs_list && this.vehicle.specs_list.length ? this.vehicle.specs_list : this.vehicle.specs
        return {
          isSpecsList,
          specs
        }
      }
    },
    methods: {
      formatPrice,
      isRental,
      ...mapMutations('order', [
        'setPostalCodeId',
        'setHomeDelivery',
      ]),
      ...mapMutations('product', [
        'setVehicleColor',
        'setDistance',
        'setMonth',
        'updateVehicle',
      ]),
      getColorStyle(colorData) {
        return colorData.secondary_hex ? `background: linear-gradient(45deg, ${colorData.hex} 50%, ${colorData.secondary_hex} 50% )` : `background: ${colorData.hex}`
      },
      setDataLayerForDownloadBrochure() {
        if (
          Object.keys(this.vehicle).length !== 0 && 
          this.vehicle &&
          isDanmarkR() && isDanmarkD() && isRenault() && isDacia()) {

          //eslint-disable-next-line
          dataLayer.push({
            brand: this.vehicle.brand,
            googleAccount: this.googleAcc,
            pageName: this.$route.name,
            languageCode: document.documentElement.lang,
            countryCode: document.querySelector('html').code,
            brochureName: this.downloadDocName,
            brochureType: this.downloadDocType,
            vehicleCategory: this.vehicle.type,
            vehicleModel: this.vehicle.model,
            vehicleId: this.vehicle.id,
            dealerName: this.resellerInfo.name,
            dealerId: this.resellerInfo.id,
            event: 'download',
            // event: 'virtualPageview',
          });
        }
      },
      getDays(days, flag) {
        const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

        return `${daysParam.number} ${daysParam.text}`;
      },
      isHasPriceData(key) {
        const { prices, } = this.vehicle;

        if (!(prices && prices.data.length)) return;

        const pricesObj = prices.data.find(item => item.finance_form === this.finance_form_name);

        return pricesObj ? pricesObj[key] : 0
      },
      checkPostCode() {
        if (this.postcode) {
          this.$set(this.$data, 'waitingForResponse', true)
          // eslint-disable-next-line no-undef
          axios.get('/postal-code', {
            headers: {
              'Authorization': this.partnerKey,
            },
            params: {
              postcode: this.postcode,
            }
          }).then(res => {
            this.$set(this.$data, 'waitingForResponse', false)
            
            if (res.status === 404) {
              this.$set(this.$data, 'postcode_validation', 'invalid')
            } else if (res.status === 200) {
              this.$set(this.$data, 'postcode_validation', 'valid')
              this.setPostalCodeId(res.data.id);
            }
          }).catch(error => {
            this.$set(this.$data, 'waitingForResponse', false)
            
            if(error.response.status === 404) {
              this.$set(this.$data, 'postcode_validation', 'invalid')
            }
          })
        }
      },
      postcodeInput() {
        this.$set(this.$data, 'postcode_validation', '')
      },
      showPopup(e) {
        if (this.isDownPayment && e.target.checked) {
          this.$set(this.$data, 'isPopup', true);
        }
      },
      closePopup() {
        this.$set(this.$data, 'isPopup', false);
      },
    },
    watch: {
      vehicle: function (n) {
        const unconfirmedStatus = this.resellerInfo.settings && this.resellerInfo.settings.bankId 
          && this.resellerInfo.settings.bankId.active ? this.resellerInfo.settings.bankId.unconfirmed_status : 1;
        const isVehicleColors = this.vehicleColorId && this.vehicle.colors && this.vehicle.colors.data.length;
        
        this.$store.dispatch('order/saveAllFields', {
          'vehicleId': n.id,
          'status': unconfirmedStatus,
        });

        if (isVehicleColors) {
          this.vehicleColor = this.vehicle.colors.data.find(item => item.id === this.vehicleColorId)
        }
      },
      vehicleColor: function (n) {
        if ((n && !!this.vehicleColorId === false) || this.vehicleColorId === undefined) {
          this.vehicleColorId = n.id
        }
      },
      vehicleColorId: function (n) {
        if (n) {
          if (this.vehicleColors) {
            this.vehicleColor = this.vehicleColors.find(item => item.id === n) || {}
          }

          this.$store.dispatch('order/saveAllFields', {'vehicleColorId': n})
        }
      },
      vehicleColors: {
        handler: function (colors) {
          let newVehicleColor = colors.find(item => item.id === this.vehicleColorId)

          if (!newVehicleColor) {
            newVehicleColor = colors.find(item => item.default)
          }

         this.vehicleColor = newVehicleColor || colors[0] || {}
        },
        deep: true
      },
      pickUpDate: function (n) {
        if (n) {
          this.$store.dispatch('order/saveAllFields', {'pickUpDate': moment(n).format('Y-MM-DD')})
          
          this.pickUpDate = n
        }
      },
      pickUpTime: function (n) {
        if (n) {
          this.$store.dispatch('order/saveAllFields', {'pickUpTime': n})
        }
      },
      deliveryDaysCount: function (n) {
        if (n) {
          this.pickUpDate = moment().add(n + 1, 'days').toDate()
        }
      },
      selectedAccessories: function (n) {
        this.$store.commit('order/setAccessories', n)
      },
      accessories: function (n) {
        if (n) {
          this.selectedAccessories = n
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '@/style/components/StepGeneral.sass'
</style>
