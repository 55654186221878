<template lang="pug">
  .product
    Notification(
      :notify="notify"
    )
    pre-loader(:show="!vehicleLoaded || preLoader")
      steps(
        @confirmed="sendOrder"
        :isConfirmed="isConfirmed"
      )
        header.order-status#order-status(
          slot="header"
          slot-scope="data"
        )
          .container.d-none.d-lg-block
            .row
              .col-md-5
                ul.order-steps
                  li.order-steps-item(
                    v-for="step in data.steps"
                    :key="step.index"
                    :class="{active: step.active}"
                    @click="data.goTo(step.index)"
                  )
                    .num {{ step.index + 1 }}
                    span {{ step.title }}
              .col-md-7
                .order-info(v-if="!isConfirmed")
                  .order-info-cell(v-if="hasDeliveryTime")
                    span.order-info-text.align-self-center {{ deliveryTimeText }}
                    span.order-info-text-big {{ deliveryDaysCountFrom.number }}
                    span.order-info-text {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
                    span.order-info-text-big {{ deliveryDaysCountTo.number }}
                    span.order-info-text {{ deliveryDaysCountTo.text }}
                  .order-info-cell
                    span.order-info-text {{ $t("price") }}
                    span.order-info-text-big {{ calculatedPrice }}
                    span.order-info-text.self-centre
                      | {{ currencySymbol }}/{{ $t('mon') }} <br>
                      span(v-if="!getIsVatIncluded") {{ $t('exmoms') }}
                  .order-info-btn(v-if="data.activeStep.index < data.steps.length - 1")
                    button.btn.btn-og.btn-hover(
                      :disabled="isDisabled"
                      @click.prevent="data.goToNext"
                    ) {{ data.activeStep.buttonLabel }}
                      i.fas.fa-chevron-right
          .d-block.d-lg-none
            .order-status-mobile
              .mobile-row
                .order-info.justify-content-start
                  .order-info-cell.order-info-mobile-steps
                    span.order-info-text.self-centre {{ $t('step') }} {{ data.activeStep.index + 1 }} {{ $t('dropDown.of') }} {{ data.steps.length }}
                  .order-info-cell.order-info-mobile-price(v-if="!isConfirmed")
                    span.order-info-text {{ $t("price") }}
                    span.order-info-text-big {{ calculatedPrice }}
                    span.order-info-text.self-centre
                      | {{ currencySymbol }}/{{ $t('mon') }} <br>
                      span(v-if="!getIsVatIncluded") {{ $t('exmoms') }}
              .mobile-row
                .order-info(v-if="data.activeStep.index < data.steps.length - 1")
                  .order-info-btn.order-info-btn-back(v-if="data.activeStep.index > 0")
                    button.btn.btn-grey.btn-hover(@click="data.goToPrev")
                      i.fas.fa-chevron-left
                      span {{ $t("back") }}

                  .order-info-btn
                    button.btn.btn-og.f-w-500.btn-hover(
                      :disabled="isDisabled"
                      :class="{ 'notouch': isDisabled }"
                      @click.prevent="data.goToNext"
                    )
                      span {{ data.activeStep.buttonLabel }}
                      i.fas.fa-chevron-right

        template(slot="steps" slot-scope="methods")
          step-general(
            @goToNext="methods.goToNext"
            @goToPrev="methods.goToPrev"
            @loaded="methods.addStep"
            :title="customStyle.step_1_text"
            :button-label="firstStepButton"
          )
          //- step-options(
          //-   @goToNext="methods.goToNext"
          //-   @goToPrev="methods.goToPrev"
          //-   @loaded="methods.addStep"
          //-   :title="customStyle.step_2_text"
          //-   :button-label="accessoryPageButton"
          //- )
          step-order(
            @goToNext="methods.goToNext"
            @goToPrev="methods.goToPrev"
            @loaded="methods.addStep"
            :title="customStyle.step_3_text"
            :button-label="orderPageButton"
          )
          step-confirmed(
            @goToNext="methods.goToNext"
            @goToPrev="methods.goToPrev"
            @loaded="methods.addStep"
            :title="customStyle.step_4_text"
            button-label="VIDARE TILL Bekräftelse"
          )
</template>

<script>
/* eslint-disable */
import {mapState, mapGetters, mapActions, mapMutations,} from 'vuex'
import Steps from '@/components/steps/Steps'

import StepGeneral from './StepGeneral'
import StepOptions from './StepOptions'
import StepOrder from './StepOrder'
import StepConfirmed from './StepConfirmed'
import {
  googleAccountRenault,
  googleAccountDacia,
  googleAccountDanmark,
  googleAccountDanmarkDacia
} from '@/config/defaultValues'


import {getPrice, formatPrice, isRenault, isDacia, isDanmarkR, isDanmarkD} from '../../../helpers/helpers'
import ZohoService from "@/services/zohoService";

export default {
  name: 'Product',
  metaInfo() {
    return {
      title: this.  metaTitle,
      titleTemplate: '%s',
      meta: [
        {
          hid: 'description',
          id: 'description',
          name: 'description',
          content: this.metaDescription,
        },
        {
          hid: 'keywords',
          id: 'keywords',
          name: 'keywords',
          content: this.metaKeywords,
        },
        {property: 'og:url', content: this.metaUrl},
        {property: 'og:locale', content: 'sv_SE'},
        {property: "og:type", content: "website"},
        {property: "og:title", content: this.metaTitle},
        {property: "og:description", content: this.metaDescription},
        {property: "og:site_name", content: "Leaseonline"},
        {itemprop: "name", content: this.metaTitle},
        {
          itemprop: "description",
          content: this.metaDescription,
        },
      ],
      link: [
        {rel: 'canonical', href: this.metaDescription}
      ],
    }
  },
  data: () => ({
    preLoader: false,
    notify: {
      type: '',
      message: '',
    },
  }),
  props: {
    isConfirmed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StepOrder,
    StepOptions,
    StepGeneral,
    StepConfirmed,
    Steps,
    Notification: () => import('./../../helps/Notification'),
  },
  computed: {
    ...mapState('global', ['isDisabled']),
    ...mapState('product', [
      'vehicleLoaded',
      'vehicleColor',
      'vehicle',
    ]),
    ...mapGetters('financing', ['totalMonthlyCost']),
    ...mapGetters('product', [
      'vehicleNotEmpty',
      'getDeliveryDaysCount',
      'deliveryDaysCountFrom',
      'deliveryDaysCountTo',
      'getBusinessType',
      'getFormCategory',
      'getIsVatIncluded',
    ]),
    ...mapState('steps', ['steps',]),
    ...mapState('order', [
      'personNumber',
      'setHomeDelivery',
    ]),
    ...mapState('reseller', [
      'partnerKey',
      'resellerInfo',
    ]),
    ...mapState('reseller', {
      hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      customStyle: state => state.customStyle.steps || {},
      firstStepButton: state => state.customStyle.firstStep?.submit_btn_text || '',
      orderPageButton: state => state.customStyle.orderPage?.submit_btn_text || '',
      accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || '',
      deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    ...mapState('filter', [
      'allTypes',
      'finance_form_name',
    ]),
    calculatedPrice() {
      const vehicleInfo = JSON.parse(localStorage.getItem('vehicleInfo'))

      let price = this.totalMonthlyCost;

      if (window.location.pathname.includes('/confirmed/')) price = vehicleInfo.price

      return formatPrice(price);
    },
    metaTitle() {
      if (!this.vehicle) return;

      const title = `${this.resellerInfo.name} ${this.vehicle.category} ${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.name}`;

      return this.isMetaExists() ? `${this.vehicle.metaData.title} ${title}` : title;
    },
    metaDescription() {
      if (!this.vehicle) return;

      const description = this.stripHtml(`${this.vehicle.description} ${this.vehicle.specs}`);

      return this.isMetaExists() ? `${this.vehicle.metaData.description} ${description}` : description;
    },
    metaKeywords() {
      if (!this.vehicle) return;

      const keywords = `${this.resellerInfo.name} ${this.vehicle.category} ${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.name}`;

      return this.isMetaExists() ? `${this.vehicle.metaData.keywords} ${keywords}` : keywords;
    },
    metaUrl() {
      if (!this.vehicle) return;

      const url = window.location.href;

      return this.isMetaExists() ? this.vehicle.metaData.url : url;
    },
    googleAccount() {
      if (isDanmarkR()) return googleAccountDanmark
      if (isDanmarkD()) return googleAccountDanmarkDacia
      if (isDacia()) return googleAccountDacia
      if (isRenault()) return googleAccountRenault
      return null;
    }
  },
  methods: {
    formatPrice,
    ...mapActions('product', [
      'getVehicle',
      'updateVehicle',
      'newDefaults',
      'updatePersonNumberError',
    ]),
    ...mapActions('order', [
      'postOrder',
      'saveAllFields',
    ]),
    ...mapMutations('global', [
      'onTheHomePage',
      'enableButton',
    ]),
    ...mapMutations('filter', [
      'setType',
      'setResellerTypeId',
    ]),
    goToNextStep() {
      this.$refs.view.goToNextStep()
    },
    goToPrevStep() {
      this.$refs.view.goToPrevStep()
    },
    async sendOrder() {
      this.preLoader = true;

      this.updatePersonNumberError('')

      try {
        const {data} = await this.postOrder();
        localStorage.setItem('vehicleInfo', JSON.stringify({
          image: this.vehicleColor && this.vehicleColor.modelColor ? this.vehicleColor.modelColor.image.image : '',
          type: this.vehicle.type,
          brand: this.vehicle.brand,
          model: this.vehicle.model,
          grade: this.vehicle.grade,
          name: this.vehicle.name,
          vehicleColor: this.vehicleColor,
          price: this.calculatedPrice,
          total_net_price: data.total_net_price,
          total_residual: data.total_residual,
        }));

        await ZohoService.sendZohoLead(data.id)

        await this.goToConfirmed(data.id);
      }
      catch {
       await this.$router.push({name: 'noorder'});
      }
    },
   async goToConfirmed(id) {
      const replaceSpaces = name => name.replace(/ /g, '-').toLowerCase();

      return this.$router.push({
        name: 'confirmed',
        params: {
          collectionType: this.$router.history.current.params.collectionType,
          type: replaceSpaces(this.vehicle.type),
          brand: replaceSpaces(this.vehicle.brand),
          model: replaceSpaces(this.vehicle.model),
          name: replaceSpaces(this.vehicle.name),
        },
        query: {
          id: this.vehicle.id,
          key: id,
        }
      });
    },
    setDataLayerForVehicle() {
      if (!this.googleAccount) return;

      dataLayer.push({
        brand: this.vehicle.brand,
        googleAccount: this.googleAccount,
        pageName: this.$route.name,
        businessType: this.getBusinessType,
        pageType: 'form',
        languageCode: document.documentElement.lang,
        countryCode: document.querySelector('html').code,
        vehicleCategory: this.vehicle.type,
        vehicleModel: this.vehicle.model,
        vehicleId: this.vehicle.id,
      });
    },
    setFormOpeningDataLayer() {
      if (!this.googleAccount) return;

      dataLayer.push({
        brand: this.vehicle.brand,
        googleAccount: this.googleAccount,
        pageName: this.$route.name,
        businessType: this.getBusinessType,
        pageType: 'form',
        languageCode: document.documentElement.lang,
        countryCode: document.querySelector('html').code,
        formName: 'order',
        formType: 'finance_request',
        formCategory: this.getFormCategory,
        formStepName: 'start',
        formStepNumber: 'step-0',
        vehicleCategory: this.vehicle.type,
        vehicleModel: this.vehicle.model,
        vehicleId: this.vehicle.id,
        event: 'formOpen',
      });
    },
    updateData(costs) {
      if (typeof costs === "undefined" || !costs.data) return;

      const cost = costs.data
          .filter(item => item.finance_form === this.finance_form_name)
          .sort((a, b) => b.months - a.months)
          .reduce((accum, value) => accum.calculated_price < value.calculated_price ? accum : value);
      this.$store.dispatch('order/saveAllFields', {'vehicleCostId': cost.id})
      this.$store.dispatch('product/updateVehicle', {'vehicleCostId': cost.id});
      this.$store.dispatch('product/setDefaultVehicleColor', cost.id);
      this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': getPrice(cost)})
    },
    async vehicleRequest() {
      const vehicleType = this.$route.params.type;
      const typeId = this.allTypes.find(item => item.type.name.toLowerCase() === vehicleType);
      if (!typeId) return;

      this.setType(typeId.type.id);
      await this.setResellerTypeId(typeId.id);

      const vehicleData = await this.getVehicle(Number(this.$route.query.id));
      this.setDataLayerForVehicle();
      this.setFormOpeningDataLayer();
      this.newDefaults(vehicleData.costs);
      this.updateData(vehicleData.costs);
    },
    orderPanelFloat() {
      function checkPos(scrollTop, num) {
        const orderStatus = document.getElementById('order-status');

        if (orderStatus === null) return;

        const height = orderStatus.offsetHeight;
        const body = document.body;

        if (scrollTop > num) {
          orderStatus.classList.add('order-status-fixed');

          // if (window.innerWidth <= 991) body.style.paddingBottom = `${height}px`;

        } else {
          const pageModel = orderStatus.nextElementSibling;

          orderStatus.classList.remove('order-status-fixed');

          pageModel.style.marginTop = '0';

          body.style.paddingBottom = '0';
        }
      }

      const currScrollTop = window.scrollY;

      if (currScrollTop > 5) {
        const orderStatusNode = document.getElementById('order-status');
        const pageModel = orderStatusNode.nextElementSibling;

        orderStatusNode.classList.add('order-status-fixed');

        pageModel.style.marginTop = '54px';
      }

      window.addEventListener('scroll', function () {
        const scrollTop = this.scrollY;

        checkPos(scrollTop, 5);
      })
    },
    isMetaExists() {
      if (this.vehicle) return false;

      return !Array.isArray(this.vehicle.metaData);
    },
    stripHtml(html) {
      const tmp = document.createElement('div');

      tmp.innerHTML = html;

      return tmp.textContent || tmp.innerText || '';
    },
  },
  watch: {
    totalMonthlyCost: function (n) {
      this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': n})
    },
    partnerKey() {
      // direct vehicle enter
      if (!this.partnerKey) return;
      this.vehicleRequest();
    },
  },
  created() {
    // route to vehicle
    if (this.vehicleNotEmpty || !this.partnerKey) return;
    this.vehicleRequest();
  },
  beforeMount() {
    this.$store.dispatch('order/clearAll');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'confirmed') this.$store.dispatch('product/clearAll');
    next();
  },
  mounted() {
    this.onTheHomePage(false);

    this.orderPanelFloat();
    this.enableButton();
  },
}
</script>
