<template lang="pug">
.page.page-model(v-if="true")
  .container
    .row
      .col-md-6.order-md-1.order-2
        .m-b-15
          v-galery(
            :main-image-url="vehicleColor && vehicleColor.modelColor && vehicleColor.modelColor.image ? vehicleColor.modelColor.image.image : ''"
            :images="vehicle.images ? vehicle.images.data : []"
            :alt="vehicle.name"
          )
      .col-md-6.order-md-2.order-1.vehicle-desc-block
        VehicleDescription(
          :title="vehicleTitle"
          :description="fullVehicleSubName"
        )
    .row.m-t-10
      .col-md-6
        .d-none.d-md-block
          .step-title 2. {{ customStyle.headline }}
        .d-md-none.d-block
          .step-subtitle {{ customStyle.headline }}
        .step-text.m-t-10(v-html="customStyle.intro")
    .row.m-t-10
      .col-md-6
        .step-subtitle.m-t-40 {{ customStyle.accessory_title }}
        label.custom-check.m-t-10.m-b-10(
          v-for="(accessorItem, index) in localAccessories"
          :for="`accessor${index}`"
          :class="{'is-invalid':errors.has('orderAccessories')}"
        )
          input.custom-check-input(
            type="checkbox"
            v-model="selectedAccessories"
            :value="accessorItem.id"
            :checked="!accessorItem.opt_in"
            :disabled="!accessorItem.opt_in"
            :id="`accessor${index}`"
            @change="showPopup($event)"
          )
          .check
            Check

          .custom-check-text.justify-content-between
            span.custom-check-text-block
              span {{ accessorItem.name }}
              span.custom-check-text-description {{ accessorItem.description }}
            span.red-text +&nbsp;{{ formatPrice(accessorItem.calculated_price) }}&nbsp;kr/mån

        div.m-t-40.m-b-20.info-block(v-if="customStyle.popup_text && isPopup " @click="closePopup")
          EffectiveRanta
          i.close.fa.fa-times-circle(
            aria-hidden="true"
            @click="closePopup"
          )

        .m-t-40.m-b-20.info-block(v-if="tradeIn.active")
          Trades

          .step-btns.m-t-20.d-none.d-lg-block(v-if="!isDisabled")
            button.step-btn.btn-hover(@click.prevent="goToNext")
              | {{ accessoryPageButton }}
              i.fas.fa-chevron-right

      .col-md-6.mobile-m-t-30
        .step-title.mobile-m-b-10 Sammanställning

        MainVehicleInfo
        DetailedVehicleInfo

        .cost-block.m-t-20
          CostDescription

        .step-btns.m-t-20.d-none.d-lg-block
          button.step-btn.btn-hover(@click.prevent="goToNext" :disabled="isDisabled")
            | {{ accessoryPageButton }}
            i.fas.fa-chevron-right
</template>

<script>
  import { mapState, } from 'vuex'

  import ProductStepMixin from '@/mixins/ProductStepMixin'

  import { formatPrice, } from '../../../helpers/helpers';

  export default {
    name: 'StepOptions',
    mixins: [ ProductStepMixin, ],
    data: () => ({
      isPopup: false,
    }),
    components: {
      Popup: () => import('@/components/helps/Popup'),
      VGalery: () => import('@/components/helps/Galery'),
      Check: () => import('@/components/svg/check.vue'),
      EffectiveRanta: () => import('@/components/steps-faceless/step-general/EffectiveRanta'),
      VehicleDescription: () => import('@/components/steps-faceless/VehicleDescription'),
      MainVehicleInfo: () => import('@/components/steps-faceless/MainVehicleInfo'),
      DetailedVehicleInfo: () => import('@/components/steps-faceless/DetailedVehicleInfo'),
      CostDescription: () => import('@/components/steps-faceless/CostDescription'),
      Trades: () => import('@/components/steps-faceless/Trades/Trades'),
    },
    computed: {
      ...mapState('global', ['isDisabled']),
      ...mapState('product', [ 'vehicleColor', 'priceForm', ]),
      ...mapState('order', [ 'accessories', ]),
      ...mapState('reseller', {
        customStyle: state => state.customStyle.accessoryPage || {},
        accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || 'VIDARE TILL BESTÄLL',
        tradeIn: (state) => state.resellerInfo.settings?.tradeIn || {},
      }),
      selectedAccessories: {
        get() {
          return this.$store.state.product.selectedAccessories
        },
        set(value) {
          this.$store.commit('product/setSelectedAccessories', value)
        }
      },
      isDownPayment() {
        return this.priceForm === 'Down payment'
      }
    },
    methods: {
      formatPrice,
      showPopup(e) {
        if (this.isDownPayment && e.target.checked) {
          this.$set(this.$data, 'isPopup', true);
        }
      },
      closePopup() {
        this.$set(this.$data, 'isPopup', false);
      }
    },
    watch: {
      selectedAccessories: function (n) {
        this.$store.commit('order/setAccessories', n)
      },
      accessories: function (n) {
        if (n) {
          this.selectedAccessories = n
        }
      }
    },
  }
</script>
