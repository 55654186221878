<template lang="pug">
.product.product-faceless-flow
  Notification(
    :notify="notify"
  )
  pre-loader(:show="!vehicleLoaded || preLoader")
    steps(
      @confirmed="sendOrder"
      :isConfirmed="isConfirmed"
    )

      template(slot="steps" slot-scope="methods")
        step-general(
          @goToNext="methods.goToNext"
          @goToPrev="methods.goToPrev"
          :title="customStyle.step_1_text"
          @loaded="methods.addStep"
          :button-label="firstStepButton"
          v-if="nfActiveStep.index < nfSteps.length && !isConfirmed"
        )
        step-order(
          @goToConfirm="methods.goToConfirm"
          @goToPrev="methods.goToPrev"
          :title="customStyle.step_3_text"
          @loaded="methods.addStep"
          :button-label="orderPageButton"
          v-if="nfActiveStep.index === 7"
        )
        step-confirmed(
          @goToNext="methods.goToNext"
          @goToPrev="methods.goToPrev"
          @loaded="methods.addStep"
          :title="customStyle.step_4_text"
          :isConfirmed="isConfirmed"
          button-label="VIDARE TILL Bekräftelse"
        )

      header.order-status(
      v-if="!isConfirmed && !preLoader"
        slot="footer"
        slot-scope="data"
      )
        .container
          .row
            .col-md-12.d-flex.align-items-center.justify-content-between
              ul.order-steps-new
                li.order-steps-item(
                  v-for="(step, i) in getPurchaseSteps"
                  v-if="step.isVisible"
                  :key="step.index"
                  :class="{active: step.index === nfActiveStep.index}"
                  @click="data.goTo(step)"
                )
                  span {{ getStepLocaleTitle(step) }}

              NextStepBtn.next-btn(@click="data.goToNext" v-if="nfActiveStep.index !== 7" )
</template>

<script>
/* eslint-disable */
import {mapState, mapGetters, mapActions, mapMutations, } from 'vuex'
import Steps from '@/components/steps-faceless/Steps'

import StepGeneral from './StepGeneral'
import StepOptions from './StepOptions'
import StepOrder from './StepOrder'
import StepConfirmed from './StepConfirmed'
import {
  googleAccountRenault,
  googleAccountDacia,
  googleAccountDanmark,
  googleAccountDanmarkDacia } from '@/config/defaultValues'

import salesForceService from '@/services/salesForceService'
import sendSalesforceService from '@/services/sendSalesforceService'

import { getPrice, formatPrice, isRenault, isDacia, isDanmarkR, isDanmarkD } from '../../../helpers/helpers'
import ProductStepMixin from '@/mixins/ProductStepMixin'
import ZohoService from "@/services/zohoService";
export default {
  name: 'Product',
  mixins: [ ProductStepMixin, ],
  metaInfo() {
    return {
      title: this.metaTitle,
      titleTemplate: '%s',
      meta: [
        {
          hid: 'description',
          id: 'description',
          name: 'description',
          content: this.metaDescription,
        },
        {
          hid: 'keywords',
          id: 'keywords',
          name: 'keywords',
          content: this.metaKeywords,
        },
        {property: 'og:url', content: this.metaUrl},
        {property: 'og:locale', content: 'sv_SE'},
        {property: "og:type", content: "website"},
        {property: "og:title", content: this.metaTitle},
        {property: "og:description", content: this.metaDescription},
        {property: "og:site_name", content: "Leaseonline"},
        {itemprop: "name", content: this.metaTitle},
        {
          itemprop: "description",
          content: this.metaDescription,
        },
      ],
      link: [
        {rel: 'canonical', href: this.metaDescription}
      ],
    }
  },
  data: () => ({
    preLoader: false,
    notify: {
      type: '',
      message: '',
    },
  }),
  props: {
    isConfirmed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StepOrder,
    StepOptions,
    StepGeneral,
    StepConfirmed,
    Steps,
    Notification: () => import('./../../helps/Notification'),
    NextStepBtn: () => import('@/components/steps-faceless/NextStepBtn.vue'),
  },
  computed: {
    ...mapState('global', ['isDisabled']),
    ...mapState('product', [
      'vehicleLoaded',
      'vehicleColor',
      'vehicle',
    ]),
    ...mapGetters('product', [
      'vehicleNotEmpty',
      'getDeliveryDaysCount',
      'deliveryDaysCountFrom',
      'deliveryDaysCountTo',
      'getBusinessType',
      'getFormCategory',
      'getIsVatIncluded',
    ]),
    ...mapGetters('steps', [ 'getPurchaseSteps', ]),
    ...mapState('order', [
      'personNumber',
      'totalMonthlyPrice',
    ]),
    ...mapState('reseller', [
      'partnerKey',
      'resellerInfo',
    ]),
    ...mapState('reseller', {
      hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      customStyle: state => state.customStyle.steps || {},
      firstStepButton: state => state.customStyle.firstStep?.submit_btn_text || '',
      orderPageButton: state => state.customStyle.orderPage?.submit_btn_text || '',
      accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || '',
      deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
      salesForce: state => state.resellerInfo.settings?.salesForce || {},
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
      tradeIn: (state) => state.resellerInfo.settings?.tradeIn || {},
      lang: state => state.resellerInfo?.market?.lang || {},
    }),
    ...mapState('filter', [
      'allTypes',
      'finance_form_name',
    ]),
    calculatedPrice() {
      const vehicleInfo = JSON.parse(localStorage.getItem('vehicleInfo'))

      let price = this.totalMonthlyPrice;

      if (window.location.pathname.includes('/confirmed/')) price = vehicleInfo.price

      return formatPrice(price);
    },
    metaTitle() {
      if (!this.vehicle) return;

      const title = `${this.resellerInfo.name} ${this.vehicle.category} ${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.name}`;

      return this.isMetaExists() ? `${this.vehicle.metaData.title} ${title}` : title;
    },
    metaDescription() {
      if (!this.vehicle) return;

      const description = this.stripHtml(`${this.vehicle.description} ${this.vehicle.specs}`);

      return this.isMetaExists() ? `${this.vehicle.metaData.description} ${description}` : description;
    },
    metaKeywords() {
      if (!this.vehicle) return;

      const keywords = `${this.resellerInfo.name} ${this.vehicle.category} ${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.name}`;

      return this.isMetaExists() ? `${this.vehicle.metaData.keywords} ${keywords}` : keywords;
    },
    metaUrl() {
      if (!this.vehicle) return;

      const url = window.location.href;

      return this.isMetaExists() ? this.vehicle.metaData.url : url;
    },
    hasSalesForce() {
      return this.salesForce.active
    },
    googleAccount() {
      if (isDanmarkR()) return googleAccountDanmark
      if (isDanmarkD()) return googleAccountDanmarkDacia
      if (isDacia()) return googleAccountDacia
      if (isRenault()) return googleAccountRenault
      return null;
    }
  },
  methods: {
    formatPrice,
    ...mapActions('product', [
      'getVehicle',
      'updateVehicle',
      'newDefaults',
      'updatePersonNumberError',
    ]),
    ...mapActions('order', [
      'postOrder',
      'saveAllFields',
    ]),
    ...mapMutations('global', [
      'onTheHomePage',
      'enableButton',
    ]),
    ...mapMutations('filter', [
      'setType',
      'setResellerTypeId',
    ]),
    getStepLocaleTitle(step) {
      return this.lang === 'da' ? step.titleDk : step.title
    },
    goToNextStep() {
      this.$refs.view.goToNextStep()
    },
    goToPrevStep() {
      this.$refs.view.goToPrevStep()
    },
    async sendOrder() {
      this.preLoader = true;

      this.updatePersonNumberError('')

      try {
        const { data } = await this.postOrder();
        localStorage.setItem('vehicleInfo', JSON.stringify({
          image: this.vehicleColor?.modelColor?.image?.image || '',
          type: this.vehicle.type,
          brand: this.vehicle.brand,
          model: this.vehicle.model,
          grade: this.vehicle.grade,
          name: this.vehicle.name,
          vehicleColor: this.vehicleColor,
          price: this.calculatedPrice,
          total_net_price: data.total_net_price,
          total_residual: data.total_residual,
        }));

        await ZohoService.sendZohoLead(data.id)

        if (this.hasSalesForce) {
          try {
            const {data: {sfdcId, correlationId, calculationTimeInMilliseconds}} = await salesForceService(data);

            await sendSalesforceService({
              sf_id: sfdcId,
              correlation_id: correlationId,
              calculation_time: calculationTimeInMilliseconds,
            });

            this.preLoader = false;
          } catch {
            // todo: handle exception
          }
        }
        await this.goToConfirmed(data.id);

      } catch(e) {
        console.error(e)
       await this.$router.push( {name: 'noorder'} );
      }
    },
    async goToConfirmed(id) {
      const replaceSpaces = name => name.replace(/ /g, '-').toLowerCase();
      return  this.$router.push({
        name: 'confirmed',
        params: {
          collectionType: this.$router.history.current.params.collectionType,
          type: replaceSpaces(this.vehicle.type),
          brand: replaceSpaces(this.vehicle.brand),
          model: replaceSpaces(this.vehicle.model),
          name: replaceSpaces(this.vehicle.name),
        },
        query: {
          id: this.vehicle.id,
          key: id,
        }
      });
    },
    setDataLayerForVehicle() {
      if (!this.googleAccount) return;

      dataLayer.push({
        brand: this.vehicle.brand,
        googleAccount: this.googleAccount,
        pageName: this.$route.name,
        businessType: this.getBusinessType,
        pageType: 'form',
        languageCode: document.documentElement.lang,
        countryCode: document.querySelector('html').code,
        vehicleCategory: this.vehicle.type,
        vehicleModel: this.vehicle.model,
        vehicleId: this.vehicle.id,
      });
    },
    setFormOpeningDataLayer() {
      if (!this.googleAccount) return;

      dataLayer.push({
        brand: this.vehicle.brand,
        googleAccount: this.googleAccount,
        pageName: this.$route.name,
        businessType: this.getBusinessType,
        pageType: 'form',
        languageCode: document.documentElement.lang,
        countryCode: document.querySelector('html').code,
        formName: 'order',
        formType: 'finance_request',
        formCategory: this.getFormCategory,
        formStepName: 'start',
        formStepNumber: 'step-0',
        vehicleCategory: this.vehicle.type,
        vehicleModel: this.vehicle.model,
        vehicleId: this.vehicle.id,
        event: 'formOpen',
      });
    },
   async updateData(costs) {
      if (typeof costs === "undefined" || !costs.data) return;

      const cost = costs.data
          .filter(item => item.finance_form === this.finance_form_name)
          .sort((a, b) => b.months - a.months)
          .reduce((accum, value) => accum.calculated_price < value.calculated_price ? accum : value);
     await this.$store.dispatch('order/saveAllFields', {'vehicleCostId': cost.id})
     await this.$store.dispatch('product/updateVehicle', {'vehicleCostId': cost.id});
     await this.$store.dispatch('product/setDefaultVehicleColor', cost.id);
     await this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': getPrice(cost)})
    },
    async vehicleRequest() {
      const vehicleType = this.$route.params.type;
      const typeId = this.allTypes.find(item => item.type.name.toLowerCase() === vehicleType);
      if (!typeId) return;

      this.preLoader = true;
      this.setType(typeId.type.id);
      await this.setResellerTypeId(typeId.id);

      const vehicleData = await this.getVehicle(Number(this.$route.query.id))
      this.setDataLayerForVehicle();
      this.setFormOpeningDataLayer();

      this.newDefaults(vehicleData.costs);
      await this.updateData(vehicleData.costs);
      this.preLoader = false;
    },
    orderPanelFloat() {
      function checkPos(scrollTop, num) {
        const orderStatus = document.getElementById('order-status');

        if (orderStatus === null) return;

        const height = orderStatus.offsetHeight;
        const body = document.body;

        if (scrollTop > num) {
          orderStatus.classList.add('order-status-fixed');

          // if (window.innerWidth <= 991) body.style.paddingBottom = `${height}px`;

        } else {
          const pageModel = orderStatus.nextElementSibling;

          orderStatus.classList.remove('order-status-fixed');

          pageModel.style.marginTop = '0';

          body.style.paddingBottom = '0';
        }
      }

      const currScrollTop = window.scrollY;

      if (currScrollTop > 5) {
        const orderStatusNode = document.getElementById('order-status');
        const pageModel = orderStatusNode.nextElementSibling;

        orderStatusNode.classList.add('order-status-fixed');

        pageModel.style.marginTop = '54px';
      }

      window.addEventListener('scroll', function(){
        const scrollTop = this.scrollY;

        checkPos(scrollTop, 5);
      })
    },
    isMetaExists() {
      if (this.vehicle) return false;

      return !Array.isArray(this.vehicle.metaData);
    },
    stripHtml(html){
      const tmp = document.createElement('div');

      tmp.innerHTML = html;

      return tmp.textContent || tmp.innerText || '';
    },
  },
  watch: {
    calculatePrice: function (n) {
      this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': n})
    },
    partnerKey() {
      // direct vehicle enter
      if (!this.partnerKey) return;
      this.vehicleRequest();
    },
  },
  created() {
    // route to vehicle
    if (this.vehicleNotEmpty || !this.partnerKey) return;
    this.vehicleRequest();
  },
  beforeMount() {
    this.$store.dispatch('order/clearAll');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'confirmed') this.$store.dispatch('product/clearAll');
    next();
  },
  mounted() {
    this.onTheHomePage(false);

    this.orderPanelFloat();
    this.enableButton();
  },
}
</script>
