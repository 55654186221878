<template lang="pug">
.collapse-box
  .collapse-button(@click="toggleShow" v-if="!alwaysShow && isTop")
    slot(name="button" :show="show")
      span.equipment-list-show.m-t-25.m-b-30
        span(v-if="!show") {{ $t('collapseBox.seeMore') }}
        span(v-else) {{ $t('collapseBox.seeLess') }}
        i.fas.fa-chevron-down.arrow(:class="{turn:show}")
  .collapse-content(:style="styleContent" :class="{show: show || alwaysShow}" )
    .slot(ref="content")
      slot
  .collapse-button(@click="toggleShow" v-if="!alwaysShow && isBottom")
    slot(name="button" :show="show")
      span.equipment-list-show.m-t-25.m-b-30
        span(v-if="!show") {{ $t('collapseBox.seeMore') }}
        span(v-else) {{ $t('collapseBox.seeLess') }}
        i.fas.fa-chevron-down.arrow(:class="{turn:show}")
</template>

<script>
  import WindowWidth from '@/mixins/WindowWidth'

  export default {
    mixins: [WindowWidth],
    props: {
      minHeight: {
        default: 80
      },
      alwaysShow: {
        default: false
      },
      overflow: {
        default: false
      },
      buttonPosition: {
        default: 'bottom'
      },
			contentShow: {
				default: false
      }
    },
    data: () => ({
      show: false,
      height: 0
    }),
    computed: {
      computedMinHeight() {
        return this.height > this.minHeight ? this.minHeight : this.height
      },
      heightContent() {
        return this.show || this.alwaysShow ? `height:${this.height}px;` : `height:${this.computedMinHeight}px;`
      },
      overflowConent() {
        if (!this.show) {
          if (this.overflow) {

            return 'overflow: hidden'
          } else {
            if (!this.alwaysShow) {

              return 'visibility: hidden; opacity: 0;'
            } else {

              return ''
            }
          }
        }

        return ''
      },
      styleContent() {
        return `${this.heightContent} ${this.overflowConent}`
      },
      isTop() {
        return this.buttonPosition === 'top'
      },
      isBottom() {
        return this.buttonPosition === 'bottom'
      },
    },
    methods: {
      toggleShow() {
        this.show = !this.show
      },
      getHeight() {
        this.$nextTick(() => {
          this.height = this.$slots.default ? this.$slots.default[0].elm.clientHeight : 0
        })
      },
    },
    mounted() {
      this.getHeight();
      window.addEventListener('resize', this.getHeight);

      this.show = this.contentShow;
    },
    updated() {
      this.getHeight()
    }
  }
</script>

<style lang="sass">
  @import '@/style/components/CollapseBox.sass'
</style>
