<template>
  <div class="steps">
    <slot
      name="header"
      :goToNext="goToNext"
      :goToPrev="goToPrev"
      :steps="shortSteps"
      :activeStep="activeStep"
      :goTo="goTo"
    />
    <slot
      name="steps"
      :goToNext="goToNext"
      :goToPrev="goToPrev"
      :addStep="addStep"
    />
  </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import {
    googleAccountRenault,
    googleAccountDacia,
    googleAccountDanmark,
    googleAccountDanmarkDacia } from '@/config/defaultValues'

  import {isRenault, isDacia, isDanmarkR, isDanmarkD } from '@/helpers/helpers'

  export default {
    data: () => ({
      steps: [],
      activeStep: {},
    }),
    props: {
      isConfirmed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('product', [ 'vehicle', ]),
      ...mapState('reseller', ['resellerInfo']),
      ...mapGetters('product', [
        'getBusinessType',
        'getFormCategory',
      ]),
      currentIndex() {
        return this.activeStep.index
      },
      shortSteps() {
        return this.steps.map(step => {
          return {
            index: step.index, 
            title: step.title, 
            active: step.active
          }
        })
      },
    },
    mounted() {
      this.activeStep = this.steps[this.isConfirmed ? 2 : 0];
      this.activeStep.active = true;

      if (!this.isConfirmed) this.changeStepRouter(0);

      this.$store.commit('global/enableButton');
    },
    methods: {
      ...mapMutations('order', ['setStatus', ]),
      ...mapMutations('product', ['setLeadID', ]),
      beforeChangeStep() {
        this.$emit('beforeChangeStep', this.activeStep);
      },
      afterChangeStep() {
        this.$emit('afterChangeStep', this.activeStep);
      },
      goToNext() {
        let index = this.currentIndex + 1;

        if (this.activeStep.validate())

          if (index < this.steps.length-1) {
            index === 1 ? this.setFormStepsDataLayer() : null;

            this.changeActiveStep(this.activeStep, this.steps[index])
            this.changeStepRouter(index);
          } else {
            this.$store.commit('global/disableButton');
            this.setResolved()
          }
      },
      goToPrev() {
        let index = this.currentIndex - 1;

        if (index >= 0 && this.currentIndex < 2) {
          this.changeStepRouter(index);

          this.changeActiveStep(this.activeStep, this.steps[index]);
          this.$store.commit('global/enableButton');
        }
      },
      goTo(index) {
        if (!(index > 1 && this.activeStep.index < 2) && !(this.activeStep.index === 2) ) {
          this.changeStepRouter(index)

          if (index < this.activeStep.index) {
            this.changeActiveStep(this.activeStep, this.steps[index])
          } else {
            if (this.activeStep.validate()){
              index === 1 ? this.setFormStepsDataLayer() : null;

              if (index >= 0 && index < this.steps.length) {
                this.changeActiveStep(this.activeStep, this.steps[index])
              }
            }
          }
          this.$store.commit('global/enableButton');
        }
      },
      setResolved() {
        const unconfirmedStatus = this.resellerInfo.settings?.bankId?.active ? 
            this.resellerInfo.settings.bankId.unconfirmed_status : 1;
        
        this.setStatus(unconfirmedStatus);

        if (isDanmarkR() || isDanmarkD() || isRenault() || isDacia())
          this.setFormCompletionsDataLayer();

        this.confirmed(); 
      },
      changeStepRouter(currentStep) {
        const searchHistoryParams = window.location.search.replace('?', '').split('&');

        let newSearch = searchHistoryParams.reduce((url, next, index) => {
            if (!next.includes('step=')) { 
              return `${url}${index === 1 ? '&' : ''}${next}`;
            } else if (next.includes('step=')) {
              return `${url}&step=step-${currentStep + 1}`;
            } else {
              return url
            }
          }, '?')

        if (newSearch.indexOf('step=') === -1) {
          newSearch += `&step=step-${currentStep + 1}`
        }

        history.replaceState({}, null, newSearch)
      },
      addStep(step) {
        step.index = this.steps.length;
        this.steps.push(step)
      },
      changeActiveStep(from, to) {
        this.beforeChangeStep();
        from.active = false;
        this.activeStep = to;
        to.active = true;
        this.afterChangeStep()

        this.$nextTick(() => {
          window.scrollTo(0, 0)
        });
      },
      confirmed() {
        this.$emit('confirmed');
      },
      setFormStepsDataLayer() {
        if (!isDanmarkR() && !isDanmarkD() && !isRenault() && !isDacia()) return;

        let acc = isDanmarkR() ? googleAccountDanmark : 
          isDanmarkD() ? googleAccountDanmarkDacia : 
          isRenault() ? googleAccountRenault : 
          isDacia() ? googleAccountDacia : '';

        // eslint-disable-next-line no-undef
        dataLayer.push({
          brand: this.vehicle.brand,
          googleAccount: acc,
          pageName: this.$route.name,
          businessType: this.getBusinessType,
          pageType: 'form',
          languageCode: document.documentElement.lang,
          countryCode: document.querySelector('html').code,
          formName: 'order',
          formType: 'finance_request',
          formCategory: this.getFormCategory,
          formStepName: 'accessories',
          formStepNumber: 'step-1',
          vehicleCategory: this.vehicle.type,
          vehicleModel: this.vehicle.model,
          vehicleId: this.vehicle.id,
          dealerName: this.resellerInfo.name,
          dealerId: this.resellerInfo.id,
          event: 'formSteps',
        });
      },
      guid(){
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
      },
      async setFormCompletionsDataLayer() {
        if (!isDanmarkR() && !isDanmarkD() && !isRenault() && !isDacia()) return;

        let acc = isDanmarkR() ? googleAccountDanmark : 
          isDanmarkD() ? googleAccountDanmarkDacia : 
          isRenault() ? googleAccountRenault : 
          isDacia() ? googleAccountDacia : '';

        const leadID = this.guid();

        await this.setLeadID(leadID)

        // eslint-disable-next-line no-undef
        dataLayer.push({
          brand: this.vehicle.brand,
          googleAccount: acc,
          pageName: this.$route.name,
          businessType: this.getBusinessType,
          pageType: 'form',
          languageCode: document.documentElement.lang,
          countryCode: document.querySelector('html').code,
          formName: 'order',
          formType: 'finance_request',
          formCategory: this.getFormCategory,
          formStepName: 'complete',
          formStepNumber: 'step-2',
          leadId: leadID,
          marketingOptinStatus: null,
          vehicleCategory: this.vehicle.type,
          vehicleModel: this.vehicle.model,
          vehicleId: this.vehicle.id,
          dealerName: this.resellerInfo.name,
          dealerId: this.resellerInfo.id,
          event: 'formValidate',
        });
      }
    },
  }
</script>
