<template lang="pug">
.v-galery#lightgallery
  .main-img
    div(:data-url="mainImageUrl || firstImage.image" :data-caption="alt")
      v-image(:image-source="mainImageUrl || ''" :alt="alt")
  .img-row.m-t-15(v-if="showThumbs" )
    a.img-row-item.gallery-item(
      v-for="(image, index) in images"
      :key="index"
      :data-caption='standardDisclaimer'
      :href="image.image"
    )
      img(:src="image.thumbnail")
</template>

<script>
  import VImage from '@/components/helps/Image'

  export default {
    props: {
      images: {
        default: () => []
      },
      mainImageUrl: {
        default: ''
      },
      alt: {},
      showThumbs: {
        default: () => true
      }
    },
    mounted() {
      const _this = this;
      const gallery = document.getElementById('lightgallery');
      let isOnce = false;

      gallery.addEventListener('mouseenter', function() {
        if (isOnce) return;

        _this.initGallery();

        isOnce = true;
      })
    },
    methods: {
      initGallery() {
        // eslint-disable-next-line
        lightGallery(document.getElementById('lightgallery'), {
          thumbnail: true,
          selector: '.gallery-item'
        });
      }
    },
    computed: {
      standardDisclaimer() {
        return this.$t("gallery.standardDisclaimer");
      },
      firstImage() {
        return this.images.length ? this.images[0] : {}
      }
    },
    components: {
      VImage
    }
  }
</script>

<style lang="sass">
  @import '@/style/components/Galery.sass'
</style>
