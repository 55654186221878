import store from '@/store/index';
import moment from 'moment';
import axios from 'axios';

const order = store.state.order;
const product = store.state.product;
const reseller = store.state.reseller;
const currentDate = moment().format();

const saleforceData = reseller.resellerInfo.settings && reseller.resellerInfo.settings.salesForce ? 
  reseller.resellerInfo.settings.salesForce : {};
const isKalpData = reseller.resellerInfo.settings ? reseller.resellerInfo.settings.kalpData : false;
const market = reseller.resellerInfo.market?.country || {};

const currencySymbol = reseller.resellerInfo.market?.currency?.symbol || 'kr';

const config = {
  headers: {
    'APIKEY': saleforceData.api_key,
    'Content-Type': 'application/json',
  }
};


const dealerID = () => {
  if (!order.home_delivery && product.vehicle.locations) {
    return product.vehicle.locations.data.find(item => item.id === order.locationId)?.dealer_code;
  } else if (order.home_delivery) {
    return order.postal_code_id;
  }
};

const calculateTotalPrice = (basePrice, accessoriesMonthlyCost, colorPrice, extraFieldCost, serviceCost) => (basePrice || 0) + (colorPrice || 0)
  + (accessoriesMonthlyCost || 0) + (extraFieldCost || 0) + (serviceCost || 0);
const calculateResidualValue = (totalPrice, residualPercent) => (totalPrice / 100 * residualPercent).toFixed(2);
const calculateExtraFields = (cost) => cost.extraFields.data.reduce((sum, item) => (sum + item.price), 0);

export default async function (orderResult) {
  const cost = product.vehicle.costs.data.find(item =>
      item.finance_form === product.priceForm
      && item.months === product.defaults[product.priceForm].months
      && item.distance === product.defaults[product.priceForm].distance);
  
  const extraFieldCost = calculateExtraFields(cost);
  const accessories = product.vehicle.accessories.data.filter(item => product.selectedAccessories.includes(item.id) );
  const accessoriesMonthlyCost = accessories.reduce((value, item) => value + item.calculated_price || 0, 0);
  const prices = product.vehicle.prices.data.find(item => item.finance_form === product.priceForm);

  let offerPackageArray = [
    `Årlig körsträcka mil: ${product.defaults[product.priceForm].distance}`,
    `Månader: ${product.defaults[product.priceForm].months}`,
    `Ränta %: ${prices.rate}`,
    `Modell: ${product.vehicle.model}`,
    `Drivlina: ${product.vehicle.name}`,
    `Grade: ${product.vehicle.grade}`,
    `Cirkapris SEK: ${prices.rsp}`,
    `Nettopris SEK: ${prices.net}`,
    `Restvärde %: ${cost.residual}`,
    `Restvärde SEK: ${calculateResidualValue(prices.rsp, cost.residual)}`,
    `Månadskostnad bil SEK: ${cost.calculated_price}`,
    `${product.vehicleColor.modelColor.name} Totalpris SEK: ${product.vehicleColor.price.total_price}`,
    `Restvärde %: ${product.vehicleColor.price.residual}`,
    `Restvärde SEK: ${calculateResidualValue(product.vehicleColor.price.total_price, product.vehicleColor.price.residual)}`,
    `Månadskostnad SEK: ${product.vehicleColor.calculated_price}`,
    ...accessories.map(item => [
      `${item.name} Totalpris SEK: ${item.total_price}`,
      `Restvärde %: ${item.residual}`,
      `Restvärde SEK: ${calculateResidualValue(item.total_price, item.residual)}`,
      `Månadskostnad SEK: ${item.calculated_price}`
    ].join(' / ')),
    `Servicekostnad SEK: ${cost.service_cost}`,
    ...cost.extraFields.data.map(item => `${item.name} SEK ${item.price}`),
    `Total månadskostnad SEK: ${calculateTotalPrice(cost.calculated_price, accessoriesMonthlyCost, product.vehicleColor.calculated_price, extraFieldCost, cost.service_cost)}`,
    `Totalpris inkl tillval: ${orderResult.total_net_price}`,
    `Totalt restvärde inkl tillval: ${orderResult.total_residual}`,
    `Övermil: ${prices.over_miles}`,
  ];

  const kreditInfo = `Kompletterande kreditinformation: ${Object.entries(order.kalp_data).reduce((sum, next, index) => `${sum} ${index !== 0 ? '/' : ''} ${next.join(': ')}`, '')}`;

  let client = {
    clientType: '',
    companyName: null,
    companyRegistrationId: null,
    lastName: order.lastName,
    firstName: order.firstName,
    email: order.email,
    mobilePhone: order.phone,
    address: {
      postalCode: order.zipCode,
      addressLine: order.address,
      city: order.city,
    },
    comment: order.comments,
    otherInformation: `RENAULT PRIVATLEASING`,
  }

  if (market.code === 'dk') {
    client = {...client, ...{
      emailMarketingOptin: 'N',
      smsMarketingOptin: 'N',
      postalMarketingOptin: 'N',
      otherInformation: `Info/Comments: ${order.comments}`,
    }}

    offerPackageArray = [
      `Årlig km. forbrug: ${product.defaults[product.priceForm].distance}`,
      `Kontraktlængde måneder: ${product.defaults[product.priceForm].months}`,
      `Model: ${product.vehicle.model}`,
      `Drivlinje: ${product.vehicle.name}`,
      `Grade: ${product.vehicle.grade}`,
      `Pris måned Kr.: ${cost.calculated_price}`,
      `${product.vehicleColor.modelColor.name} Pris måned Kr.: ${product.vehicleColor.calculated_price}`,
      ...accessories.map(item => [
        `${item.name} Pris måned Kr.: ${item.calculated_price}`
      ].join(' / ')),
      `Samlet omkostningar måned Kr.: ${calculateTotalPrice(cost.calculated_price, accessoriesMonthlyCost, product.vehicleColor.calculated_price, extraFieldCost, cost.service_cost)}`,
      `Udbetaling Kr.: ${product.vehicle.nonRefundableCashPayment}`, 
      `Samlet leasingydelse i perioden: ${product.vehicle.totalCost} ${currencySymbol}`,
      `Halvårlig ejerafgift: ${prices.half_year_tax_fee} ${currencySymbol}`, 
      `Afleveringsgebyr: ${prices.delivery_cost} ${currencySymbol}`,
    ];
  }

  return axios.post(saleforceData.url, {
    leadInfo: {
      leadCountry: saleforceData.lead_country,
      platformBrand: saleforceData.platform_brand,
      // TODO: set once of this: ["Commercial Information - Enterprise Customer","Commercial Information - Enterprise Customer","Test Drive","VO","VN","APVA","APVM","ENT","GD","RRENT","RSHOP","VN/VO","APV","APV-A","APV-M","NEW_CAR","USED_CAR","AFTER_SALES", "SERVICES","MOBILITY"]
      typeOfInterest: 'VN',
      leadSource: 'INTERNET',
      origin: 'Leaseonline',
      campaign: 'Leaseonline',
      device: '',
      subTypeOfInterest: 'Finance request',
      leadSubmissionId: Date.now().toString(),
      context: 'Renault Online Privatleasing ',
      leadProvider: 'leaseonline',
      leadFormName: 'leaseonline',
      leadSubmissionTimestamp: currentDate,
      leadFormId: product.leadID
    },
    vehicle: {
      brandOfInterest: product.vehicle.brand,
      modelOfInterest: product.vehicle.model,
      version: '',
      mileage: product.defaults[product.priceForm].distance,
      energy: product.vehicle.engine,
      equipments: ''
    },
    dealer: {
      dealerOfInterest: dealerID()
    },
    client,
    finance: {
      fundingRequest: true,
      offerPackageName: ' / ' + offerPackageArray.join(' / ') + (isKalpData ? ' / ' + kreditInfo : '')
    },
    services: {
      serviceContractsRequest: true
    }
  },
  config)
}
